.CarouselItem{
    height: 85px;
}

.CarouselItem img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.2s;    
}

.CarouselItem img:hover{
    transform: scale(1.02);
}

@media (max-width: 1220px) {
    
    .CarouselItem{
        height: 75px;    
    }
    
}

@media (max-width:1024px){
    .CarouselItem{
        height: 65px;
    }
}

@media (max-width:420px){
    .CarouselItem{
        height: 50px;
    }
}
