.AboutMain{
    height: calc(100vh - 70px);
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.About{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 3rem;
}

.AboutBody {
    display: flex;
    justify-content: center;
    gap: 1em;
    height: 40vh;
    
}

.About h1{
    font-size: 3.5em;
    letter-spacing: 2px;
}

.AboutBody h2{
    font-size: 2.3em;
    letter-spacing: 1px;
}

.AboutBody h3{
    font-size: 1.5em;
}


.AboutText, .AboutSkills{
    background-color: hsl(0, 0%, 98%);
    border-radius: 40px;
    width: 40%;
    padding: 10px 40px;
}

.AboutSkills{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4em;
}

.AboutText span{
    text-align: justify;
    line-height: 34px;
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    text-align: justify;
}

.AboutText h2{
    margin-top: 0.5rem;
}

.AboutText h2, .AboutText h3{
    margin-bottom: 0.5em;
}

.AboutText h3{
    text-align: center;
}

.Abstract{
    height: 1.5%;
    width: 100%
}

.Abstract img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: contrast(0.9);
    border-radius: 2rem;
    border: none;
}

@media (max-width: 1220px) {

    .About h1{
        font-size: 3em;
        letter-spacing: 2px;
    }
    
    .AboutBody h2{
        font-size: 1.8em;
        letter-spacing: 1px;
    }
    
    .AboutBody h3{
        font-size: 1.3em;
    }

    .AboutText span{
        font-size: 1em;
        text-align: justify;
        line-height: 30px;
    }

}

@media (max-width: 1024px) {
    
    .About{
        gap: 1rem;
        padding-top: 1em;
    }
    .AboutBody{
        height: fit-content;
    }
    .AboutSkills{
        width: 70%;
        height: 30vh;
        gap: 2em;
    }
    .AboutText{
        width: 70%;
    }
    .AboutBody{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .About h1{
        font-size: 2.5em;
        letter-spacing: 2px;
    }
    
    .AboutBody h2{
        font-size: 1.7em;
        letter-spacing: 1px;
        text-align: center;
    }
    
    .AboutBody h3{
        font-size: 1.2em;
    }
  
    .AboutText span{
        font-size: 0.8em;
        text-align: justify;
        line-height: 30px;
    } 
}   

@media (max-width: 1000px) {

    
    .About h1{
        font-size: 2.5em;
        letter-spacing: 2px;
    }
    
    .AboutBody h2{
        font-size: 1.7em;
        letter-spacing: 1px;
    }
    
    .AboutBody h3{
        font-size: 1.2em;
    }

    .AboutText span{
        font-size: 0.8em;
        text-align: justify;
        line-height: 30px;
    }
    .AboutText,.AboutSkills{
        width: 80%;
    }
}
@media (max-height:900px){

    .Abstract{
        display: none;
    }
}
@media (max-width: 400px) {

    .AboutMain{
        height: fit-content;

    }
    .AboutBody{
        font-size: 1rem;
    }

    .AboutSkills{
        gap: 1.5em;
        width: fit-content;
    }
    .AboutSkills, .AboutText{
        padding: 10px 20px;
    }
}

@media (max-height: 800px) {

    .AboutBody{
        height: fit-content;
    }

}