.PortfolioMain {
    min-height: calc(100vh - 70px);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
}

.Title h1{
    font-size: 3.5em;
    letter-spacing: 2px;
    margin: 0;
}

.Cards{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    background-color: hsl(0, 0%, 98%);
    border-radius: 40px;
    padding: 40px;
}

@media (max-width: 1024px) {

    .PortfolioMain {
        gap: 2rem;
    }
    
    .Cards {
        flex-direction: column;
        gap: 0px;
        padding:1vh 40px;
    }

    .Title h1{
        font-size: 4em;
    }
}

  
@media (max-width: 1000px) {
  
    .Title h1 {
      font-size: 3em;
    }
}
  
@media (max-width: 700px) {
   
    .Title h1 {
      font-size: 3.2em;
    }

}
  
@media (max-width: 450px) {
    
    .Title h1 {
      font-size: 2.7em;
    } 
}
  
@media (max-width: 375px) { 
    
    .Title h1 {
      font-size: 2.4em;

    }
}
  
@media (max-width: 350px) {
  
    .Title h1 {
      font-size: 2.4em;
    }

}

/* Medida iPhone 13*/

@media (max-height: 800px) and (orientation:portrait) {

    .PortfolioMain{
        gap: 0rem;
    }
    
    .Cards{
        padding:0.1vh 40px;
    }
}

