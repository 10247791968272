.InputContainer{
    width:100%;
    display: flex;
    flex-direction: column;
}

.InputContainer input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: 'Lato', sans-serif;
    transition: all 0.3s;
    border-bottom: 2px solid #000;
    font-size: 1em;
}

.Error{
    margin-top: 1rem;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
}

input:focus {
    border-bottom: 2px solid #406aff;
}

.InputContainer .Error{
    color:red
}

@media (max-width:670px){
    .InputContainer input, .Error{
        font-size: 0.9em;
    }
}