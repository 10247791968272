.Carousel{
    width: 40vw;
}

@media (max-width: 1220px) {
    
    .Carousel{
        width: 30vw;
        min-width: 300px;
    }
}

@media (max-width: 1024px) {
    .Carousel{
        width: 60vw;
    }
}

