.Button{
    width:fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    color: #fff;
    background-color: #4069ffa4;
    transition: transform 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    pointer-events: none;
    user-select: none;
}
.Button.enabled{
    pointer-events: initial;
    background-color: #406aff;

}
.Button.enabled:hover{
    background-color: #14A44D;
    transform: scale(1.05);
    cursor: pointer;
}

@media (max-width:412px){
    
    .Button{
        font-size: 0.7rem;
    }
}