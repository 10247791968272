.Card {
  /*     border: 1px solid #ccc;
 */
  margin: 10px;
  width: 50vw;
  height: 30vw;
  overflow: hidden;
  border-radius: 10px;
  text-align: center;
  border-radius: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Card:hover {
  background-color: #000;
}

.Card .Overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;  
  background-color: rgba(0, 0, 0, 0.511);
  color: #fff;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 2;
  border-radius: 10px;

}

.Card:hover .Overlay {
  color: #fff;
  opacity: 1;
}

.Overlay h3{
  text-decoration: underline;
}


.Overlay p{
  width:80%;
}

.Overlay a{
  color: #fff;
  text-decoration: none;
}


.Card img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: height 0.3s, width 0.3s;
  z-index: 1;
}

.Card:hover img {
  width: 200%;
  height: 200%;
  opacity: 0.5;
}

@media (min-width: 1025px) {
  
  .Card{
    width: 20vw;
    height: 290px;
  }
}

/* @media (max-height: 800px) {
  
    .Card{
      height: 160px;
    }
} */

