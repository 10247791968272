body {
  background: #f4f4f4;
  font-family: 'Lato', sans-serif;
}

*{
  font-family: 'Average', serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Mukta', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}