.Form{
    height: calc(100vh - 70px);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.FormBox::before {
    position: absolute;
    content: "";
    width: 60%;
    height: 70%;
    background: #406aff;
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: sliderShape 5s linear infinite;
    display: block;
    opacity: 0.1;
}

.FormBox{
    position: relative;
    padding: 30px;
    background-color: hsl(0, 0%, 98%);
    border-radius: 40px;
    display: flex;
    justify-content: center;
}

@keyframes sliderShape {
    0%,
    100% {
      border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
      transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
      transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
      transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
      transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
  }

.Form form{
    width: 500px;
    display:flex;
    flex-direction: column;
    gap:3rem;
    align-items: flex-end;
    position: relative;
}
.Title h1{
    font-size: 3.5em;
    letter-spacing: 2px;
}

.Alert{
  position: absolute;
  width: max-content;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.03rem;
  font-weight: bolder;

}

@media (max-width:670px){
  .Form form{
    width:70vw
  }
}

@media (max-width: 1220px) {

  .About h1{
      font-size: 3em;
      letter-spacing: 2px;
  }
  
  .AboutBody h2{
      font-size: 1.8em;
      letter-spacing: 1px;
  }
  
  .AboutBody h3{
      font-size: 1.3em;
  }

  .AboutText span{
      font-size: 1em;
      text-align: justify;
      line-height: 30px;
  }

}

@media (max-width: 1024px) {
  
  .Form h1{
      font-size: 2.5em;
      letter-spacing: 2px;
  } 

}   

@media (max-width: 1000px) {

  
  .About h1{
    font-size: 2.5em;
    letter-spacing: 2px;
  }

  .Form form{
    font-size: 0.8em;
  }

}

@media (max-height: 600px){
  
  .Form form{
    gap:1rem;
  }

  .FormBox::before {

    top: 10vh;
    width: 60%;
    height: 55%;

}
}