.NavBar {
    display: flex;
    justify-content: space-around;
    background-color: #050303;
    height: 70px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: border-bottom 0.3s;
    border-bottom: none;
    overflow: hidden;
    transition: box-shadow 0.5s ease-in-out;
}
  
.NavBar::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.3px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
}
  
.NavBar:hover::before {
    transform: scaleX(1);
    box-shadow: 0px 3px 20px rgba(255, 255, 255, 0.8);
}

.Logo{
    width: auto;
    height: 35px;
}

.Logo img{
    width:100%;
    height:100%;
    object-fit: contain;
}

.Options{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
    list-style-type: none;
    letter-spacing: 1,5px;
}

.Options a{
    text-decoration: none;
    color: #e3e4e5;
    font-size: 0.85em;
    font-family: 'Lato', sans-serif;
    transition: font-size 0.15s;
}

.Options a:hover{
    font-size: 0.95em;
    color: #406aff;
}

.Options ul{
    margin: 0px;
}

@media (max-width: 700px) {

    
    .CenteredOptions{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .CenteredOptions a{
        font-size: 0.75em;
    }

    .Options a:hover{
        font-size: 1.05em;
    }
}

@media (max-width: 460px) {

    .CenteredOptions a{
        font-size: 0.85em;
    }
}

@media (max-width: 460px) {

    .CenteredOptions a{
        font-size: 0.75em;
    }
}

@media (max-width: 350px) {

    .CenteredOptions a{
        font-size: 0.70em;
    }

    .Options a:hover{
        font-size: 0.9em;
    }
}


@media (max-width: 330px) {

    .CenteredOptions a{
        font-size: 0.55em;
    }

    .Options a:hover{
        font-size: 0.9em;
    }
}

