.HomeMain {
  height: calc(100vh - 60px);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(90deg, #406aff 5%, #ad33de, 50%, #b563b1 80%, #677ed4 100%);
}


@keyframes sliderShape {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

.HomeMain img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.HomeContent {
  z-index: 2;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5em;
}

.Photo {
  width: 160px;
  height: 160px;
}

.Photo img {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 200px;
  border: none;
  object-fit: contain;
  transition: transform 0.5s;
  background-color: #fff;
  opacity: 0.97;
  padding: 1rem;
  background-color: #0D160B;
}

.Photo > img:hover {
  transform: scale(1.05);
}

.Content{
    display: flex;
    flex-direction: column;
}

.Info {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.3em;
  position: relative;
  gap: 5em;
}

.Info::before {
  position: absolute;
  content: "";
  width: 32em;
  height: 120%;
  background: #0D160B;
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  animation: sliderShape 5s linear infinite;
  display: block;
  z-index: -1;
}

.Info h3 {
  font-size: 3em;
  font-weight: 700;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}

.Info h1 {
  font-size: 5.5em;
  color: #406aff;
  text-shadow: 1px 1px 1px #5f5f5f;
  transition: transform 0.3s;
}

.Info h1:hover {
  animation-name: color;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  color: #b563b1;
  transform: scale(1.02);
}

.Links{
    position: relative;
    top: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.Links a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    letter-spacing: 1.2px;
    gap: 3px;
    color: #000;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: column;
    transition: transform 0.5s;
    font-weight: bolder;
    cursor: pointer;
}

.Links a:hover{  
    transform: scale(1.15);
}

.icon{
    font-size: 1.6rem;
    z-index: 1;
    margin-bottom: 0.3rem;
}

@keyframes color {
  from {
    color: #406aff;
  }
  to {
    color: rgb(255, 255, 255);
  }
}

@media (max-width: 1000px) {

  .Info::before {
    width: 20em;
  }

  .Info h3 {
    font-size: 2em;

  }
  
  .Info h1 {
    font-size: 4em;
  }

  .Photo {
    width: 120px;
    height: 120px;
  }

  .Links{
    gap: 3em;
  }

  .Links a{
    font-size: 0.8rem;
  }

  .icon{
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}
}

@media (max-width: 1000px) {

  .Info{
    gap: 3em;
  }

  .HomeContent{
    gap: 3em;
  }

  .Info h3 {
    font-size: 1.5em;

  }
  
  .Info h1 {
    font-size: 3em;
  }

  .Photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
  }

  .Links{
    gap: 3em;
  }

  .Links a{
    font-size: 0.8rem;
  }

  .icon{
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}
}

@media (max-width: 700px) {

  .Info{
    gap: 3em;
  }

  .HomeContent{
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  .Info h3 {
    font-size: 1.5em;

  }
  
  .Info h1 {
    font-size: 3.2em;
  }

  .Photo {
    width: 120px;
    height: 120px;
  }

  .Links{
    gap: 2em;
  }

  .Info h1{
    color: #fff;
  }

}

@media (max-width: 450px) {
  
  .Info{
    gap: 2.5em;
  }

  .Photo {
    width: 100px;
    height: 100px;
  }

  .Info h3 {
    font-size: 1.2em;

  }
  
  .Info h1 {
    font-size: 2.7em;
  }

  .Links{
    gap: 1.5em;
  }

  .Links a{
    font-size: 0.8rem;
  }

  .icon{
    font-size: 1rem;
    margin-bottom: 0.4rem;
}
}

@media (max-width: 375px) {

  .Info{
    gap: 2.5em;
  }

  .Photo {
    width: 90px;
    height: 90px;
  }

  .Info::before {
    width: 15em;
  }

  .Info h3 {
    font-size: 1em;

  }
  
  .Info h1 {
    font-size: 2.4em;
  }

  .Links{
    gap: 1em;
  }

  .icon{
    margin-bottom: 0.3rem;
}
}

@media (max-width: 350px) {

  .Info{
    gap: 2.5em;
  }

  .Photo {
    width: 90px;
    height: 90px;
  }

  .Info::before {
    width: 15em;
  }

  .Info h3 {
    font-size: 1em;

  }
  
  .Info h1 {
    font-size: 2.4em;
  }

  .Links{
    margin: 0px 1;
    gap: 0.5em;
  }

  .Links a{
    letter-spacing: 1px;

  }

  .icon{
    margin-bottom: 0.3rem;
}
}